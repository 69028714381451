import './style.scss'
import TweetGrid from '../../components/TweetGrid'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useEffect, useRef, useState } from 'react'
import Topbar from '../../components/Topbar'
import { getPastEvents } from '../../util/interact'
import tweets from '../../assets/formatted_tweets.json'

function splitChunks(arr, len) {
    let chunks = [],
        i = 0,
        n = arr.length
    while (i < n) {
        chunks.push(arr.slice(i, (i += len)))
    }
    return chunks
}

function Main() {
    let container = useRef(null)
    let [events, setEvents] = useState([])
    const [scrollLeft, setScrollLeft] = useState(0)
    let [max, setMax] = useState(0)

    useEffect(() => {
        const element = container.current
        if (element) {
            setMax(container.current.scrollWidth - container.current.clientWidth - 15)
            element.scrollTop = (element.scrollHeight - element.clientWidth) / 2
            element.scrollLeft = (element.scrollWidth - element.clientHeight) / 2
            setScrollLeft((element.scrollWidth - element.clientHeight) / 2)
        }
    }, [])

    useEffect(() => {
        async function fetchData() {
            setEvents(await getPastEvents())
            setInterval(async () => {
                setEvents(await getPastEvents())
            }, 3000)
        }
        fetchData().then(() => true)
    }, [])

    return (
        <div className={`App ${scrollLeft > 15 ? 'fadedLeft' : ''} ${scrollLeft < max ? 'fadedRight' : ''}`}>
            <Topbar />
            <ScrollContainer
                onScroll={() => {
                    if (container.current.scrollLeft <= 15 && scrollLeft > 15) {
                        setScrollLeft(container.current.scrollLeft)
                    } else if (container.current.scrollLeft > 15 && scrollLeft <= 15) {
                        setScrollLeft(container.current.scrollLeft)
                    }

                    if (container.current.scrollLeft >= max && scrollLeft < max) {
                        setScrollLeft(container.current.scrollLeft)
                    } else if (container.current.scrollLeft < max && scrollLeft >= max) {
                        setScrollLeft(container.current.scrollLeft)
                    }
                }}
                className="scroll-container"
                innerRef={container}>
                {splitChunks(tweets, 10).map((chunk, i) => (
                    <TweetGrid key={i} index={i} events={events} data={chunk} />
                ))}
            </ScrollContainer>
            {/*<Minter></Minter>*/}
        </div>
    )
}

export default Main
