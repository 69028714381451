import Tweet from '../Tweet'

const TweetGrid = ({ data, ethPrice, events, index }) => {
    return (
        <div className="TweetGrid">
            {data.map((_info, i) => (
                <Tweet
                    key={index * 10 + i + 1}
                    index={index * 10 + i + 1}
                    events={events}
                    info={_info}
                />
            ))}
        </div>
    )
}

export default TweetGrid
