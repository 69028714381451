require('dotenv').config()
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY
const contractABI = require('../abi.json')
const contractAddress = '0xc2FCe7D84B5664b2BA9Bfd0614a2457b19227e1B'
const { createAlchemyWeb3 } = require('@alch/alchemy-web3')
const web3 = createAlchemyWeb3(alchemyKey)

const Web3 = require('web3')
if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider)
    window.ethereum.enable()
}

export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            if (window.ethereum.networkVersion !== '1')
                return {
                    status: 'Please connect to the mainnet ethereum network.',
                    address: ''
                }
            const addressArray = await window.ethereum.request({
                method: 'eth_requestAccounts'
            })

            return {
                status: '',
                address: addressArray[0]
            }
        } catch (err) {
            return {
                address: '',
                status: '😥 ' + err.message
            }
        }
    } else {
        return {
            address: '',
            status: (
                <span>
                    <p>
                        {' '}
                        🦊{' '}
                        <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
                            You must install Metamask, a virtual Ethereum wallet, in your browser.
                        </a>
                    </p>
                </span>
            )
        }
    }
}

export const getPastEvents = () =>
    new Promise(async (resolve, reject) => {
        if (!window.web3) return resolve([])
        const contract = new window.web3.eth.Contract(contractABI, contractAddress)
        let eventFilter = await contract.getPastEvents('TokenMinted', {
            fromBlock: 7700000,
            toBlock: 'latest' // You can also specify 'latest'
        })

        console.log('events', eventFilter)

        resolve(eventFilter)
    })

export const mint = async tweetNumber => {
    if (window.ethereum.networkVersion !== '1') throw new Error('You need to be connected to the mainnet')
    try {
        const mintValue = window.web3.utils.toWei('0.5', 'ether')
        const contract = new window.web3.eth.Contract(contractABI, contractAddress)

        const value = window.web3.utils.toHex(mintValue)
        const data = contract.methods.mintToken(tweetNumber).encodeABI()
        const transactionParameters = {
            to: contractAddress,
            from: window.ethereum.selectedAddress,
            value,
            data
        }
        const gasEstimate = await window.ethereum.request({
            method: 'eth_estimateGas',
            params: [transactionParameters]
        })
        const maxPriorityGas = await web3.eth.getMaxPriorityFeePerGas()
        const baseFee = Number(await window.web3.eth.getBlock('pending').baseFeePerGas)
        const max = Number(maxPriorityGas) + baseFee - 1
        transactionParameters.gas = window.web3.utils.toHex(parseInt((parseInt(gasEstimate) * 1.5).toFixed(0)))
        transactionParameters.maxPriorityFeePerGas = maxPriorityGas
        transactionParameters.maxFeePerGas = max
        return await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [transactionParameters]
        })
    } catch (error) {
        return error
    }
}
