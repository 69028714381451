import Blockhead from '../../assets/img/blockhead.jpg'
import { useEffect, useRef, useState } from 'react'
import { mint } from '../../util/interact'

const Tweet = ({ info, events, index }) => {
    const ref = useRef(null)
    const [sold, setSold] = useState(false)
    const [minting, setMinting] = useState(false)
    const [requested, setRequested] = useState(false)
    const [confirmed, setConfirmed] = useState('')
    const [txHash, setTxHash] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    let date = new Date(info.created_at).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric'
    })

    useEffect(() => {
        if (events.find(el => el.returnValues['tokenId'] === index.toString())) {
            setSold(true)
        }
    }, [events, index])

    const mintTweet = async () => {
        try {
            setRequested(true)
            let txHash = await mint(index)
            let transactionReceipt = null

            if (txHash.message) {
                setRequested(false)
                if (txHash.message.includes('insufficient funds for gas'))
                    return setErrorMessage('Insufficient funds to execute this action')
                return setErrorMessage(txHash.message)
            }

            setMinting(true)

            setTxHash(txHash)

            while (transactionReceipt == null) {
                console.log('checking')

                transactionReceipt = await window.web3.eth.getTransactionReceipt(txHash)
                console.log('transactionReceipt', transactionReceipt)

                if (transactionReceipt !== null) setConfirmed(transactionReceipt)
                await sleep(10000)
            }
        } catch (e) {
            setErrorMessage(e.message)
            setRequested(false)
        }
    }

    return (
        <>
            <div className={errorMessage !== '' ? 'fullPageOverlay show' : 'fullPageOverlay'}>
                <div className="error-text">{errorMessage}</div>
                <div className="button" onClick={() => setErrorMessage('')}>
                    Close
                </div>
            </div>
            <div className={sold ? 'tweet sold' : 'tweet'} ref={ref} id={index}>
                <div className={requested ? 'overlay show' : 'overlay'}>
                    {!confirmed && (
                        <div className="lds-default">
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                    )}
                    <div className="info-text">
                        Transaction{' '}
                        {minting ? (confirmed ? 'Confirmed!' : 'Submitted...') : requested ? 'Requested' : ''}{' '}
                        {txHash !== '' && (
                            <a href={'https://etherscan.io/tx/' + txHash} target="_blank" rel="noreferrer">
                                View
                            </a>
                        )}
                    </div>
                </div>
                <div className="top">
                    <div className="left">
                        <div className="user">
                            <img src={Blockhead} alt="blockhead" />
                            <div className="details">
                                <div className="name">Blockhead</div>
                                <div className="at">@OhYouBlockhead · {date}</div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        {sold ? (
                            <div className="sold">SOLD</div>
                        ) : (
                            <div className="price">
                                <div className="price-in-eth">0.5Ξ</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="body" style={{ whiteSpace: 'pre-line' }}>
                    {info.text}
                </div>
                <div className="bottom">
                    {!sold ? (
                        <>
                            <div className="left">
                                <div className="first">Available for minting</div>
                                <div className="hidden">Mint it now!</div>
                            </div>
                        </>
                    ) : (
                        <div className="left">
                            Owned By:{' '}
                            {events.find(el => el.returnValues['tokenId'] === index.toString())['returnValues']['to']}
                        </div>
                    )}
                    {!sold && (
                        <div className="right">
                            <div className="button yellow" onClick={mintTweet}>
                                MINT FOR 0.5Ξ
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

const sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export default Tweet
