import { HiMenu, HiX } from 'react-icons/hi'
import './topbar.scss'
import { useEffect, useState } from 'react'
import { connectWallet } from '../../util/interact'

const Topbar = () => {
    const [menu, toggleMenu] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [address, setAddress] = useState()

    const connect = async () => {
        let res = await connectWallet()
        if (res.status !== '') setErrorMessage(res.status)
        else setAddress(res.address)
    }

    useEffect(() => {
        if (window.ethereum && window.ethereum.selectedAddress && window.ethereum.networkVersion === '1')
            setAddress(window.ethereum.selectedAddress)
    }, [])

    const openNewTab = url => {
        window.open(url, '_blank')
    }

    return (
        <>
            <div className={errorMessage !== '' ? 'fullPageOverlay show' : 'fullPageOverlay'}>
                <div className="error-text" style={{ whiteSpace: 'pre-line' }}>
                    {errorMessage}
                </div>
                <div className="button" onClick={() => setErrorMessage('')}>
                    Close
                </div>
            </div>
            <div className="topbar">
                <div className="left">Blockhead.Market</div>
                <div className="right">
                    <div className={menu ? 'menu open' : 'menu closed'}>
                        <div className="actual-items">
                            <div className="menu-item" onClick={() => openNewTab('https://twitter.com/OhYouBlockhead')}>
                                Twitter
                            </div>
                            <div
                                className="menu-item"
                                onClick={() => openNewTab('https://opensea.io/collection/good-griefs')}>
                                Open Sea
                            </div>
                            <div
                                className="menu-item"
                                onClick={() =>
                                    openNewTab(
                                        'https://etherscan.io/address/0xc2fce7d84b5664b2ba9bfd0614a2457b19227e1b'
                                    )
                                }>
                                Etherscan
                            </div>
                            <div
                                className="menu-item"
                                onClick={() =>
                                    setErrorMessage(
                                        <div className="faq">
                                            <div className="question">About:</div>
                                            <div className="line">
                                                Good Griefs is a collection of the 250 best Blockhead tweets from over
                                                four years.
                                            </div>
                                            <div className="line">
                                                Sparked by a Lord of the Flies childhood, life as a professional
                                                gambler, and stints at a crypto startup and screenwriting, each of these
                                                pieces is inspired, or completely pulled, from real life. Sometimes a
                                                scratchpad, sometimes a urinal, this Twitter feed has been one of the
                                                few things to endure over this time period.
                                            </div>
                                            <div className="line">
                                                Each selection is intended to communicate a feeling to the reader. When
                                                going to an emotional extreme, often a painful process, you don’t forget
                                                how you got there. And when it’s someone else who takes you there, you
                                                remember you aren’t alone.
                                            </div>
                                            <div className="line">Once minted, the tweet is permanently deleted.</div>
                                            <div className="question">Distribution:</div>
                                            <div className="line">
                                                There are 30 tweets reserved for friends, family, and devs.{' '}
                                            </div>
                                            <div className="line">
                                                The remaining 220 tweets are available for 0.5 ETH to the public.
                                            </div>
                                            <div className="line">
                                                There will never be new tweets minted as part of this collection.{' '}
                                            </div>
                                            <div className="line">
                                                The owners of each NFT will be sent a hardcover book with all 250 pieces
                                                and a foreword.
                                            </div>
                                            <div className="line">
                                                Owners will be contacted the first week of December for private
                                                shipping.
                                            </div>
                                            <div className="question">About the Artist:</div>
                                            <div className="line">
                                                Blockhead is a former professional poker player. He is currently a
                                                screenwriter in Los Angeles and a professional card counter, the subject
                                                of his next project.
                                            </div>
                                        </div>
                                    )
                                }>
                                FAQ
                            </div>
                        </div>
                        <div className="icon">
                            <div className="open" onClick={() => toggleMenu(true)}>
                                <HiMenu />
                            </div>
                            <div className="close" onClick={() => toggleMenu(false)}>
                                <HiX />
                            </div>
                        </div>
                    </div>
                    <div className={address && address !== '' ? 'button' : 'button enabled'} onClick={connect}>
                        {address && address !== '' ? 'Connected' : 'Connect'}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Topbar
